<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section)
</script>

<template>
  <div
    v-for="[key] of Object.entries(data)"
    :key="`xXfjuC${key}`"
  >
    <Layout0Section1 v-if="key === 'section1'" />
    <Layout0Section2 v-else-if="key === 'section2'" />
    <Layout0Section3 v-else-if="key === 'section3'" />
    <Layout0Section4 v-else-if="key === 'section4'" />
    <Layout0Section5 v-else-if="key === 'section5'" />
    <Layout0Section6 v-else-if="key === 'section6'" />
    <Layout0Section7 v-else-if="key === 'section7'" />
    <Layout0Section8 v-else-if="key === 'section8'" />
    <Layout0Section9 v-else-if="key === 'section9'" />
    <Layout0Section10 v-else-if="key === 'section10'" />
    <Layout0Section11 v-else-if="key === 'section11'" />
    <Layout0Section12 v-else-if="key === 'section12'" />
    <Layout0Section13 v-else-if="key === 'section13'" />
    <Layout0Section14 v-else-if="key === 'section14'" />
    <Layout0Section15 v-else-if="key === 'section15'" />
    <Layout0Section16 v-else-if="key === 'section16'" />
    <Layout0Section17 v-else-if="key === 'section17'" />
    <Layout0Section18 v-else-if="key === 'section18'" />
    <Layout0Section19 v-else-if="key === 'section19'" />
    <Layout0Section20 v-else-if="key === 'section20'" />
    <Layout0Section21 v-else-if="key === 'section21'" />
    <Layout0Section22 v-else-if="key === 'section22'" />
    <Layout0Section23 v-else-if="key === 'section23'" />
    <Layout0Section24 v-else-if="key === 'section24'" />
    <Layout0Section25 v-else-if="key === 'section25'" />
    <Layout0Section26 v-else-if="key === 'section26'" />
    <Layout0Section27 v-else-if="key === 'section27'" />
    <Layout0Section28 v-else-if="key === 'section28'" />
    <Layout0Section29 v-else-if="key === 'section29'" />
    <Layout0Section30 v-else-if="key === 'section30'" />
    <Layout0Section31 v-else-if="key === 'section31'" />
    <Layout0Section32 v-else-if="key === 'section32'" />
    <Layout0Section33 v-else-if="key === 'section33'" />
    <Layout0Section34 v-else-if="key === 'section34'" />
    <Layout0Section35 v-else-if="key === 'section35'" />
    <Layout0Section36 v-else-if="key === 'section36'" />
    <Layout0Section37 v-else-if="key === 'section37'" />
    <Layout0Section38 v-else-if="key === 'section38'" />
  </div>
</template>
