<script setup lang="ts">
const { locale } = useI18n()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section37)
</script>

<template>
  <!-- Start block 37 -->
  <section v-if="data?.status" class="bg-white text-gray-800">
    <div class="container mx-auto max-w-screen-xl px-5 py-24">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-16">
        <!-- stat - start -->
        <div v-if="data?.list1?.length">
          <div v-if="data?.text1" class="mb-8 text-3xl sm:text-4xl font-bold">
            {{ data?.text1 }}
          </div>
          <div
            v-for="(item, index) in data?.list1"
            :key="`xfzeAt${index}`"
            class="pb-6 mb-6 border-b"
          >
            <div v-if="item?.text1" class="text-2xl font-bold mb-3">
              {{ item?.text1 }}
            </div>
            <div v-if="item?.hotline" class="text-xl">
              <div
                v-for="(ite, ind) in item?.hotline"
                :key="`ZASPNp${ind}`"
                class="pb-2"
              >
                <div class="text-lg">
                  {{ ite?.name }}
                </div>
                <div class="font-bold text-indigo-500 text-3xl">
                  <NuxtLinkLocale
                    :to="`tel:${ite?.phone}`"
                    :hreflang="locale"
                    class="hover:underline"
                  >
                    {{ ite?.phone }}
                  </NuxtLinkLocale>
                </div>
              </div>
            </div>
            <div v-if="item?.text2" class="text-xl">
              {{ item?.text2 }}
            </div>
            <div v-if="item?.text3" class="text-xl">
              {{ item?.text3 }}
            </div>
            <div v-if="item?.phone" class="font-bold text-indigo-500 text-3xl">
              <NuxtLinkLocale
                :to="`tel:${item?.phone}`"
                :hreflang="locale"
                class="hover:underline"
              >
                {{ item?.phone }}
              </NuxtLinkLocale>
            </div>
            <div v-if="item?.facebook" class="text-xl overflow-x-auto">
              Facebook:
              <NuxtLinkLocale
                :to="item?.facebook"
                :hreflang="locale"
                target="_blank"
                class="hover:underline"
              >
                {{ item?.facebook }}
              </NuxtLinkLocale>
            </div>
            <div v-if="item?.zalo" class="text-xl overflow-x-auto">
              Zalo:
              <NuxtLinkLocale
                :to="item?.zalo"
                :hreflang="locale"
                target="_blank"
                class="hover:underline"
              >
                {{ item?.zalo }}
              </NuxtLinkLocale>
            </div>
            <div v-if="item?.tiktok" class="text-xl overflow-x-auto">
              Tiktok:
              <NuxtLinkLocale
                :to="item?.tiktok"
                :hreflang="locale"
                target="_blank"
                class="hover:underline"
              >
                {{ item?.tiktok }}
              </NuxtLinkLocale>
            </div>
          </div>
        </div>
        <!-- stat - end -->
        <!-- stat - start -->
        <div v-if="data?.list2?.length">
          <div v-if="data?.text2" class="mb-8 text-3xl sm:text-4xl font-bold">
            {{ data?.text2 }}
          </div>
          <div
            v-for="(item, index) in data?.list2"
            :key="`xfzeBa${index}`"
            class="pb-6 mb-6 border-b"
          >
            <div v-if="item?.text1" class="text-2xl font-bold mb-3">
              {{ item?.text1 }}
            </div>
            <div v-if="item?.text2" class="text-xl">
              {{ item?.text2 }}
            </div>
            <div v-if="item?.text3" class="text-xl">
              {{ item?.text3 }}
            </div>
            <div v-if="item?.items" class="text-xl">
              <!-- List -->
              <ul role="list" class="my-6 space-y-4 text-left">
                <li
                  v-for="(ite, ind) in item?.items"
                  :key="`gyGaxL${ind}`"
                  class="flex items-center space-x-3"
                >
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" /></svg>
                  <span class="text-lg">
                    {{ ite }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- stat - end -->
      </div>
    </div>
  </section>
  <!-- End block 37 -->
</template>
