<script setup lang="ts">
const { locale } = useI18n()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section29)
</script>

<template>
  <!-- Start block 29 -->
  <section v-if="data?.status" class="bg-gray-50 text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 pt-32 pb-16">
      <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
        <div>
          <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
            <img fetchpriority="high" :src="data?.image1" loading="lazy" alt="" class="h-full w-full object-cover object-center">
          </div>
        </div>

        <div class="md:pt-8">
          <div v-if="data?.text1" class="mb-3 text-center font-bold text-gray-500 md:text-left">
            {{ data?.text1 }}
          </div>

          <div v-if="data?.text2" class="mb-6 text-4xl sm:text-5xl font-bold">
            {{ data?.text2 }}
          </div>

          <p v-if="data?.text3" class="mb-6 leading-relaxed text-2xl sm:text-xl">
            {{ data?.text3 }}
          </p>

          <div class="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
            <div>
              <NuxtLinkLocale
                :to="data?.link1?.href ?? '/#'"
                :hreflang="locale"
                class="inline-flex items-center text-xl lg:text-lg font-medium text-sky-500 hover:text-sky-800 dark:text-sky-500 dark:hover:text-sky-700"
              >
                {{ data?.link1?.text }}
                <svg class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
              </NuxtLinkLocale>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 29 -->
</template>
