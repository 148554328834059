<script setup lang="ts">
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section36)
</script>

<template>
  <!-- Start block 36 -->
  <section v-if="data?.status && data?.list?.length" :id="data?.anchor" class="bg-white text-gray-800">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <h2 class="mb-6 text-4xl sm:text-5xl font-bold tracking-tight">
          {{ data?.title }}
        </h2>
        <p class="text-2xl sm:text-xl text-gray-600">
          {{ data?.caption }}
        </p>
      </div>
      <div v-if="data?.list?.length" class="space-y-8 lg:grid lg:grid-cols-4 gap-6 lg:space-y-0">
        <!-- Pricing Card -->
        <div
          v-for="(item, index) in data?.list"
          :key="`LZxsHX${index}`"
          class="flex flex-col p-2 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-3"
        >
          <h3 v-if="item?.text1" class="mb-4 text-3xl font-semibold">
            {{ item?.text1 }}
          </h3>
          <p v-if="item?.text2" class="text-xl text-gray-600">
            {{ item?.text2 }}
          </p>
          <div class="flex justify-center items-baseline my-8">
            <span class="mr-2 text-4xl font-bold">
              {{ item?.text3 }}
            </span>
          </div>

          <button
            type="button"
            class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-2xl sm:text-xl px-5 py-2.5 text-center"
            @click="navigateTo(localePath(item?.button?.href ?? '/#'), { external: true })"
          >
            {{ item?.button?.text }}
          </button>

          <!-- List -->
          <ul role="list" class="my-8 space-y-4 text-left">
            <li
              v-for="(ite, ind) in item?.items"
              :key="`UgXXyL${ind}`"
              class="flex items-center space-x-3"
            >
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" /></svg>
              <span class="text-lg">
                {{ ite }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 36 -->
</template>
