<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section38)
</script>

<template>
  <!-- Start block 38 -->
  <section v-if="data?.status" class="bg-white/80 text-gray-800 bg-cover bg-center bg-blend-overlay" :style="`background-image: url(${data?.background})`">
    <div class="container mx-auto max-w-screen-xl px-5 pt-32 pb-16">
      <BookBusTicketSearch />
    </div>
  </section>
  <!-- End block 38 -->
</template>
