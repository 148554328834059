<script setup lang="ts">
const { locale }: any = useI18n()

const viewport = useViewport()

const sitedata: any = useSiteData()

const section1 = computed(() => sitedata.value?.home_data?.section1)
</script>

<template>
  <!-- Start block -->
  <section v-if="section1?.status && section1?.list?.length" class="bg-green-500 text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 pt-20">
      <UCarousel
        :items="section1?.list"
        :ui="{
          item: 'w-full lg:h-[35rem]',
          indicators: {
            active: 'bg-gray-500 dark:bg-gray-400',
          },
        }"
        :arrows="viewport.isGreaterOrEquals('tablet')"
        :indicators="viewport.isLessThan('tablet')"
      >
        <template #default="{ item }">
          <div class="text-center mx-auto">
            <div class="grid md:grid-cols-12 h-full">
              <div class="text-left p-3 xl:p-16 mr-auto place-self-center md:col-span-7">
                <p v-if="item?.text1" class="text-4xl lg:text-5xl xl:text-6xl text-white font-bold">
                  {{ item?.text1 }}
                </p>
                <div v-if="item?.text2">
                  <div v-if="_isArray(item?.text2)">
                    <p
                      v-for="(text2Item, text2Index) in item?.text2"
                      :key="`bjzwZZ${text2Index}`"
                      class="text-xl text-white mt-3"
                    >
                      {{ text2Item }}
                    </p>
                  </div>
                  <p v-else class="text-xl text-white mt-3">
                    {{ item?.text2 }}
                  </p>
                </div>

                <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mt-10">
                  <NuxtLinkLocale
                    v-if="item?.button1"
                    :to="item?.button1?.href ?? '/#'"
                    :hreflang="locale"
                    class="inline-flex items-center justify-center w-full px-5 py-3 text-2xl sm:text-xl font-medium text-center text-white border border-green-500 rounded-lg sm:w-auto bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-gray-100"
                  >
                    {{ item?.button1?.text }}
                  </NuxtLinkLocale>

                  <NuxtLinkLocale
                    v-if="item?.button2"
                    :to="item?.button2?.href ?? '/#'"
                    :hreflang="locale"
                    class="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-2xl sm:text-xl font-medium bg-white border border-green-500 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-green-500 focus:z-10 focus:ring-4 focus:ring-gray-200"
                  >
                    {{ item?.button2?.text }}
                  </NuxtLinkLocale>
                </div>
              </div>

              <div class="hidden md:col-span-5 md:flex place-self-center w-[90%] h-[90%]">
                <img :src="item?.image1" alt="" class="w-full h-full object-contain" draggable="false">
              </div>
            </div>
          </div>
        </template>

        <template #prev="{ onClick, disabled }">
          <UButton
            color="gray"
            icon="i-heroicons-chevron-left-16-solid"
            :disabled="disabled"
            size="xl"
            class="bg-transparent shadow-none border-0 ring-0 text-white hover:bg-transparent disabled:bg-transparent absolute top-[80%] sm:top-[45%] start-0 xl:-start-15"
            :ui="{
              icon: { size: { xl: 'h-16 w-16' } },
            }"
            @click="onClick"
          />
        </template>

        <template #next="{ onClick, disabled }">
          <UButton
            color="gray"
            icon="i-heroicons-chevron-right-16-solid"
            :disabled="disabled"
            size="xl"
            class="bg-transparent shadow-none border-0 ring-0 text-white hover:bg-transparent disabled:bg-transparent absolute top-[80%] sm:top-[45%] end-0 xl:-end-15"
            :ui="{
              icon: { size: { xl: 'h-16 w-16' } },
            }"
            @click="onClick"
          />
        </template>
      </UCarousel>
    </div>
  </section>
  <!-- End block -->
</template>
